/* Variables
------------------------------------- */

:root {
  --box-shadow-color: #D3D3D3;
  --box-shadow: 3px 3px 0;
  --color-primary-darker: #2d47ce;
  --color-purple: #7614b6;
  --color-purple-dark-mode: #c773ef;
  --color-blue: #4B66F2;
  --color-gold: #efbb35;
  --color-gold-darker: #dda414;
  --color-grey: #f3f3f3;
  --color-grey-2: #e8e8e8;
  --color-grey-3: #D3D3D3;
  --color-grey-dark: #a7a7a7;
  --color-grey-darker: #525252;
  --color-green: #5ef24b;
  --color-cyan: #4da39d;
  --color-cyan-dark-mode: #4ac7bf;
  --color-red: #f24bc6;
  --color-orange: #f65e5e;
  --color-pink: #f24bc6;
  --color-black: #212121;
  --color-black-full: #080808;
  --color-black-2: #272727;
  --color-white: #ffffff;
  --color-white-2: #fdfdfd;
  --color-primary: var(--color-orange);
  --color-bg: var(--color-white);
  --color-text: var(--color-black);
  --color-bg-section: var(--color-grey);
  --color-bg-section-2: var(--color-grey-2);
  --color-card-text: var(--color-text);
  --color-card-background: var(--color-white);
  --color-card-border: transparent;
  --color-twitter: #1DA1F2;
  --color-linkedin: #2867B2;
  --color-github: #AD5C51;
  --color-spotify: #1DB954;
  --dashed-border: 1px dashed;
  --font-size-root: 17px;
  --font-size: 1rem;
  --font-line-height: 1.8;
  --font-weight: 400;
  --font-family-system: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
  --work-list-pseudo-color: var(--color-grey-3);
  --work-pseudo-color: var(--color-grey-3);
  --color-white-2: #fdfdfd;
  --loading-bg-color: 255, 255, 255;
  --transition: .06s ease-in-out;
}

@value breakpoint-xs: 320px;
@value breakpoint-sm: 576px;
@value breakpoint-md: 768px;
@value breakpoint-lg: 992px;
@value breakpoint-xl: 1024px;
@value breakpoint-xx: 1200px;
