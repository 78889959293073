@value breakpoint-lg from '../../assets/styles/variables.css';

.section-gists {
  position: relative;
  min-height: 300px;
  background-color: var(--color-bg-section-2);
}

.section-link {
  composes: link from '../../assets/styles/helpers.css';
  text-align: center;
}

.gists-loading svg {
  width: 84px;
  animation: loading-animation 1.5s infinite ease-in-out;
  transition: var(--transition);
}

@media (min-width: breakpoint-lg) {
  .section-link { margin-top: 3rem; }
}

@keyframes loading-animation {
  0% { color: var(--color-text); }
  20% { color: var(--color-orange); }
  40% { color: var(--color-purple); }
  60% { color: var(--color-cyan); }
  80% { color: var(--color-gold); }
}
