@value breakpoint-lg from '../../assets/styles/variables.css';
@value breakpoint-xx from '../../assets/styles/variables.css';

.section-work { background-color: var(--color-bg-lighter); }

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* ----- About ----- */
.about {
  order: 1;
  margin-top: 3rem;
}

.about h4 {
  font-size: 1.2rem;
  line-height: 1.9rem;
  font-weight: 600;
  margin-bottom: .2rem;
}

.about p { margin-bottom: 1.5rem; }

.ad {
  composes: link from '../../assets/styles/helpers.css';
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1.5em 0;
  line-height: 3rem;
  text-align: center;
  border-top: var(--dashed-border) var(--color-primary);
}

.ad img { margin-left: .5rem; }

.linkedin {
  width: 22px;
  height: 22px;
}

/* ----- Work Timeline ----- */
.list {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: .8rem;
}

/* ----- List: center line ----- */
.list::before {
  content: '';
  position: absolute;
  top: 52px;
  left: 50%;
  display: block;
  width: 2px;
  height: calc(100% - 52px);
  background-color: var(--work-pseudo-color);
  transform: translateX(-50%);
}

.list-item {
  display: grid;
  grid-template-columns: 1fr;
}

.list-item-card {
  composes: card from '../../assets/styles/helpers.css';
  composes: card-hover from '../../assets/styles/helpers.css';
  display: flex;
  align-items: center;
  order: 1;
  position: relative;
  width: 272px;
  margin: 0 auto;
  padding: 0.7rem;
  border: 1px solid var(--box-shadow-color);
}

.list-item-remote,
.list-item-hybrid {
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  font-size: 0.7rem;
  color: white;
  padding: 0 0.5rem;
  background: var(--color-primary);
}

/* ----- List Card: circle ----- */
.list-item-card::before {
  content: '';
  position: absolute;
  top: -57px;
  left: 50%;
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 100%;
  background-color: var(--color-primary);
  transform: translateX(-50%);
  z-index: 2;
}

.list-item-date {
  margin: 2rem auto;
  font-size: 1.06rem;
  font-weight: 600;
  z-index: 1;
}

.list-item-date span {
  display: inline-block;
  width: 90px;
  text-align: center;
}

/* ----- List Card: content ----- */
.logo {
  width: 50px;
  height: 50px;
  margin-right: 0.8rem;
  background-image: url('../../assets/images/jobs/jobs.jpg');
  background-repeat: no-repeat;
  background-size: 199px;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  box-shadow: var(--box-shadow) var(--box-shadow-color);
}

.logo-nexhealth { background-position: -150px -50px; }
.logo-ciklum { background-position: -150px 0; }
.logo-payvision { background-position: 0 0; }
.logo-webdoctor { background-position: -50px 0; }
.logo-neoway {  background-position: -100px 0; }
.logo-div64 {  background-position: 0 -50px; }
.logo-elogroup {  background-position: -50px -50px; }
.logo-bolt-brasil {  background-position: -100px -50px; }

.title {
  font-size: .9rem;
  font-weight: 600;
}

.position {
  padding-top: .15rem;
  font-size: .8rem;
}

@media (hover: hover) {
  .list-item-card:hover::before {
    background-color: var(--color-primary);
  }

  .list-item-card:hover .title {
    color: var(--color-primary);
  }
}

@media (min-width: breakpoint-lg) and (max-width: breakpoint-xx) {
  .content,
  .about,
  .list { flex-direction: row; }

  .content { margin: 3rem 0; }

  .about {
    order: 0;
    width: 40%;
    margin-top: 1rem;
    padding-right: 2rem;
  }

  .list {
    width: 60%;
    height: 520px;
    flex-wrap: wrap;
    justify-content: center;
  }

  .list::before {
    top: 58px;
    height: calc(100% - 77px);
    background-color: var(--work-list-pseudo-color);
  }

  .list-item { margin-bottom: 2rem; }

  .list-item-card { height: 90px; }

  .list-item-card::before { top: -26px; }

  .list-item-date {
    margin: 0 auto;
    align-self: center;
  }
}


@media (min-width: breakpoint-xx) {
  .content {
    flex-direction: row;
    margin: 4rem 0;
  }

  .about {
    order: 0;
    margin-top: 1rem;
    padding-right: 1rem;
    width: calc(100% - 704px);
  }

  .list {
    width: 670px;
    height: 100%;
    margin: 0;
  }

  .list::before {
    top: 64px;
    height: calc(100% - 110px);
  }

  .list-item {
    grid-template-columns: 342px auto;
    justify-content: left;
    align-items: center;
    margin-top: 1.2rem;
  }

  .list-item-left {
    grid-template-columns: auto auto;
    align-self: flex-end;
    padding-right: 0.2rem;
  }

  .list-item-card {
    width: 272px;
    margin: 0;
  }

  .list-item-card:hover::after {
    background-color: var(--color-primary);
  }

  .list-item-card::after {
    content: '';
    position: absolute;
    top: 50%;
    right: -58px;
    display: block;
    width: 58px;
    height: 2px;
    background-color: var(--work-pseudo-color);
    transform: translateY(-50%);
  }

  .list-item-left .list-item-card::after {
    left: -56px;
    width: 56px;
  }

  .list-item-card::before {
    top: 50%;
    right: 0;
    left: calc(100% + 57px);
    transform: translateY(-50%);
  }

  .list-item-left .list-item-card::before { left: -67px; }

  .list-item-left .list-item-date {
    order: 0;
    padding-right: 92px;
    padding-left: 0;
  }

  .list-item-date {
    width: auto;
    margin: 0;
    padding-left: 24px;
    order: 1;
  }

  .list-item-date span {
    width: auto;
    padding: 0 .15rem;
  }

  .list-item-date span:first-child:after { content: ' - '; }
}
