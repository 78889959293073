.list { display: flex; }

.list-item::before { content: '|'; }

.list-item:first-child::before { display: none; }

.list-item:first-child .language { 
  margin-left: 0;
  padding-left: 0;
}

.language { 
  display: inline-block;
  margin: 0 .4rem;
  padding-bottom: .2rem;
  font-size: .9rem;
  line-height: .9rem;
  border-bottom: var(--dashed-border) transparent;
  opacity: .5;
  transition: var(--transition);
}

.language.active {
  opacity: 1;
  border-color: var(--color-primary);
}

@media (hover: hover) {
  .language:hover {
    color: var(--color-text);
    border-color: var(--color-primary);
    opacity: 1;
  }
}
