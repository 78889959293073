@value breakpoint-md from '../../../assets/styles/variables.css';

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 2rem;
  height: 32px;
  width: 32px;
  overflow: hidden;
  border: 3px solid var(--color-primary);
  border-radius: 100px;
  transition: var(--transition);
}

.button:active { top: 1px }

.arrow {
  position: relative;
  width: 18px;
  height: 18px;
  transition: var(--transition);
}

@keyframes scroll-up {
  0%   { transform: translateY(40px); }
  100% { transform: translateY(-70px); }
}

@media (hover: hover) {
  .button:hover { border-color: var(--color-primary); }

  .button:hover .arrow { 
    animation: scroll-up 1s infinite cubic-bezier(0.0, 0.6, 1.0, 0.4);
  }
}

@media (min-width: breakpoint-md) {
  .button { margin-top: 0; }
}
