@value breakpoint-md from '../../assets/styles/variables.css';
@value breakpoint-lg from '../../assets/styles/variables.css';

.section-projects { background-color: var(--color-bg-section); }

.list {
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-gap: 3rem;
  margin: 3rem auto;
}

.link {
  composes: card from '../../assets/styles/helpers.css';
  composes: card-hover from '../../assets/styles/helpers.css';
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  padding-top: 0.9rem;
  overflow: hidden;
}

.status {
  position: absolute;
  top: 16px;
  left: -28px;
  padding: .1rem 0;
  width: 110px;
  font-size: 0.58rem;
  text-align: center;
  text-transform: uppercase;
  color: var(--color-white-2);
  background-color: var(--color-primary);
  transform: rotate(-45deg);
}

.link-wrapper {
  display: flex;
  align-items: center;
}

.favicon {
  width: 32px;
  height: 32px;
}

.name {
  flex-grow: 1;
  padding: 0 0.5rem;
  font-size: 1.2rem;
  font-weight: 700;
}

.link-icon {
  width: 22px;
  height: 22px;
  transform: scale(0.8);
}

.description {
  padding-top: 0.4rem;
  font-size: 0.94rem;
  line-height: 1.6;
}

@media (hover: hover) {
  .link:hover .name {
    color: var(--color-primary);
  }
}

@media (min-width: breakpoint-md) {
  .list {
    margin: 3.6rem auto 2.6rem;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: breakpoint-lg) {
  .list {
    grid-template-columns: repeat(3, 1fr);
    max-width: 1200px;
  }
}
