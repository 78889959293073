/* Link
------------------------------------- */
.link {
  padding-bottom: .1rem;
  border-bottom: var(--dashed-border) var(--color-primary);
  transition: var(--transition);
}

@media (hover: hover) {
  .link:hover {
    color: var(--color-primary);
  }
}

/* Cards
------------------------------------- */
.card {
  padding: 1rem;
  color: var(--color-card-text);
  background: var(--color-card-background);
  border: 3px solid var(--color-card-border);
  box-shadow: var(--box-shadow) var(--box-shadow-color);
  transition: var(--transition);
}

@media (hover: hover) {
  .card-hover:hover {
    color: var(--color-card-text);
    border-color: var(--color-primary);
    box-shadow: 5px 5px 5px var(--color-grey-dark);
  }
}
