@value breakpoint-sm from '../../assets/styles/variables.css';
@value breakpoint-lg from '../../assets/styles/variables.css';

.section-header {
  padding-top: 3rem;
  text-align: center;
}

.header {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

.header-picture { position: relative; }

.picture-hint {
  position: absolute;
  padding: 0.05rem .4rem;
  font-size: .65rem;
  color: var(--color-white);
  background: var(--color-primary);
  border-radius: 5px;
  z-index: 1;
  opacity: 0;
  transform: 
    rotate(-35deg)
    translate(0, 20px);
}

.picture-hint::before {
  content: '';
  position: absolute;
  top: calc(100% - 1px);
  left: 50%;
  display: block;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid var(--color-primary);
  transform: translateX(-50%);
}

:global(.home-initialized) .picture-hint {
  animation: hint 15s infinite cubic-bezier(0.41, -0.12, 0.61, 1.43) 2.5s;
}

.heading {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.greeting {
  padding-top: 0.6rem;
  font-size: 1.2rem;
  line-height: 2rem;
}

.name {
  padding-top: 0.7rem;
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 0.95;
}

.position {
  color: var(--color-primary);
  font-size: 1.45rem;
  line-height: 2.4rem;
}

.bio-wrapper {
  margin: 3rem auto 2rem;
  font-size: 1.2rem;
  line-height: 2.1rem;
  text-align: center;
}

.punch-line {
  display: inline-block;
  margin-bottom: 1rem;
  padding-bottom: 1rem;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 2.2rem;
  border-bottom: var(--dashed-border) var(--color-primary);
}

.bio,
.personality-bio {
  font-size: 1.2rem;
  line-height: 1.9rem;
}

.personality-bio { color: var(--color-primary); }

@media only screen and (min-width: breakpoint-sm) {
  .section-header {
    padding-top: 2rem;
  }

  .header {
    flex-direction: row;
    padding-top: 1.5rem;
    min-width: 420px;
  }

  .heading {
    text-align: left;
    margin-left: .6rem;
  }

  .greeting {
    padding: 0 0 0 0.3rem;
    font-size: 1.2rem;
    line-height: 1.4;
  }

  .name {
    padding-top: .4rem;
    font-size: 2.6rem;
    line-height: 2.7rem;
  }

  .position {
    padding-left: 0.2rem;
    font-size: 1.6rem;
    line-height: 2rem;
  }

  .bio-wrapper { max-width: 78%; }
}

@media (min-width: breakpoint-lg) {
  .bio-wrapper {
    max-width: 70%;
    margin: 4rem auto;
  }

  .punch-line {
    margin-bottom: 0.7rem;
    font-size: 2.26rem;
    font-weight: 300;
    line-height: 3.2rem;
  }

  .bio,
  .personality-bio {
    width: 87%;
    margin: 0 auto;
    font-size: 1.33rem;
    line-height: 2rem;
  }
}

@keyframes hint {
  0% {
    opacity: 0;
    transform:
      scale(.2)
      rotate(-35deg)
      translate(0, 20px);
  }
  4%, 98% {
    opacity: 1;
    transform:
      rotate(-35deg)
      translate(0, -20px);
  }
  100% {
    opacity: 0;
    transform:
      scale(0)
      rotate(-35deg)
      translate(0, 20px);
  }
}
