:global(.home) { 
  opacity: 0; 
}

:global(.home):global(.home-initialized) {
  animation: fade-content .8s cubic-bezier(0.22, 0.61, 0.36, 1) .1s forwards;
}

@keyframes fade-content {
  0% {
    opacity: 0;
    transform: translateY(70px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
