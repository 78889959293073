@value breakpoint-md from '../../../assets/styles/variables.css';

.header { text-align: center; }

.anchor { cursor: initial; }

.title {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 600;
}

.subtitle {
  font-size: 1.05rem;
  line-height: 1.76rem;
  font-weight: 400;
}


@media (min-width: breakpoint-sm) {
  .title {
    font-size: 1.7rem;
    line-height: 2.1rem;
  }
  
  .subtitle {
    font-size: 1.2rem;
    line-height: 1.9rem;
  }
}
