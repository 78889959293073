@value breakpoint-md from '../../assets/styles/variables.css';

.footer-section { background-color: var(--color-bg-section) }

.footer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.languages,
.social,
.top-wrapper { margin-bottom: 2.2rem; }

.actions {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  width: 340px;
  justify-content: space-between;
}

.top-wrapper,
.bottom-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.copyright {
  margin-bottom: .5rem;
  font-size: .9rem;
}

.quote { font-size: .8rem; }

.quote-link {
  composes: link from '../../assets/styles/helpers.css';
  font-style: italic;
}

@media only screen and (min-width: breakpoint-md) {
  .top-wrapper,
  .bottom-wrapper {
    flex-direction: row;
  }

  .languages,
  .social,
  .copyright { margin-bottom: 0; }

  .top-wrapper { margin-bottom: 1.5rem; }

  .actions {
    flex-direction: row;
  }
}
